
import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { appConfig } from 'src/config';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';

@Component({
	selector: 'orders-form',
	templateUrl: 'orders-form.component.html',
	styleUrls: ['orders-form.component.scss'],
})
export class OrdersFormComponent implements OnInit 
{

	Form: FormGroup;

	currentPage: any = 1;
	perPage: number;
	index: any = 1;
	totalPages: number;

	stageCheck = 1;
	Customers: any[] = [];

	OrderItems: any[] = [];

	Addresses: any[] = [];

	sample_type: any = '';
	min_temperature: any = '';
	max_temperature: any = '';
	sample_quantity: any = '';
	storage_type: any = '';

	dropoff_address: any = '';
	pickup_address: any = '';

	urgency: any = 'normal'

	DropoffLocation: string;
	PickupLocation: string;

	dropOff: any = {
		address: 'Select Address',
		person_name: 'Select Address',
		person_phone: 'Select Address'
	};
	pickUp: any = {
		address: 'Select Address',
		person_name: 'Select Address',
		person_phone: 'Select Address'
	};
	orderTime: any;
	orderDate: any;

	sub: Subscription;
	id: any;
	bar_code: any = '';
	isEditing: boolean;

	constructor(private mainApiService: MainService, protected fb: FormBuilder,  protected _route: ActivatedRoute, protected router: Router, protected dialog: MatDialog) 
	{
		this.isEditing = false;
		this.Form = this.fb.group({
			partner_id: [null],

			pickup_latitude: [null],
			pickup_longitude: [null],
			pickup_address: [null],
			pickup_phone: [null],
			pickup_name: [null],

			dropoff_latitude: [null],
			dropoff_longitude: [null],
			dropoff_address: [null],
			dropoff_phone: [null],
			dropoff_name: [null],

			urgency: ['urgent'],

			pickup_date: [null],
			pickup_time: [null],
			items: [],
		});
	}

	ngOnInit() 
	{
		this.getCustomersList();

		this.sub = this._route.params.subscribe(params => {
			this.id = params['id'];
			if (this.id != 'add')
			{
				this.isEditing = true;
				this.Form.addControl('id', new FormControl(this.id));
				this.onViewOrder();
			}
			else
			{
				this.isEditing = false;
			}
		});
	}

	onViewOrder(): void
	{
		let url = '/view-orderitems?order_id=' + this.id;
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			console.log(result)
			if (result.status === 200 && result.data) 
			{
				let data = result.data[0];

				this.OrderItems = data.order_items;

				this.dropOff = {
					address: data.dropoff_address,
					person_name: data.dropoff_name,
					person_phone: data.dropoff_phone
				};

				this.pickUp = {
					address: data.pickup_address,
					person_name: data.pickup_name,
					person_phone: data.pickup_phone
				};

				this.orderTime = new Date(data.pickup_date +' '+ data.pickup_time);
				this.orderDate = data.pickup_date;
				
				this.dropoff_address = data.dropoff_address;

				this.pickup_address = data.pickup_address;

				this.urgency = data.urgency;

				this.getControlName('partner_id').setValue(data.partner_id);

				this.PickupLocation = data.pickup_latitude + ',' + data.pickup_longitude;
				this.DropoffLocation = data.dropoff_latitude + ',' + data.dropoff_longitude;

				this.getControlName('pickup_latitude').setValue(this.pickUp.latitude);
				this.getControlName('pickup_longitude').setValue(this.pickUp.longitude);
				this.getControlName('pickup_address').setValue(this.pickUp.address);
				this.getControlName('pickup_phone').setValue(this.pickUp.person_phone);
				this.getControlName('pickup_name').setValue(this.pickUp.person_name);

				this.getControlName('dropoff_latitude').setValue(this.dropOff.latitude);
				this.getControlName('dropoff_longitude').setValue(this.dropOff.longitude);
				this.getControlName('dropoff_address').setValue(this.dropOff.address);
				this.getControlName('dropoff_phone').setValue(this.dropOff.person_phone);
				this.getControlName('dropoff_name').setValue(this.dropOff.person_name);

				let oD = moment(this.orderDate).format('YYYY-MM-DD');
				this.getControlName('pickup_date').setValue(oD);
				let oT = moment(this.orderTime).format('HH:mm:ss');
				this.getControlName('pickup_time').setValue(oT);

				this.getControlName('urgency').setValue(this.urgency);
			}
			else 
			{
				
			}
		});
	}

	getCustomersList(): void 
	{
		let url = '/view-users?page=' + 1 + '&per_page=' + this.perPage + '&role_type=partner&user_status=1';
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Customers = result.data;
			}
			else 
			{
				this.Customers = [];
			}
		});
	}

	getAddressList(): void 
	{
		let url = '/view-location?id=' + this.Form.get('partner_id').value;
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Addresses = result.data;
			}
			else 
			{
				this.Addresses = [];
			}
		});
	}

	getControlName(name) 
    { 
        return this.Form.get(name); 
	}

	getControlValue(name) 
    { 
        return this.Form.get(name).value; 
	}

	onNext() 
	{
		
		if (this.stageCheck != 4) 
		{
			this.stageCheck++;
		}
		if (this.stageCheck == 2) 
		{
			this.getAddressList();
		}
	}

	onPrevious() 
	{
		if (this.stageCheck != 1) 
		{
			this.stageCheck--;
		}
		if (this.stageCheck == 2) 
		{
			this.getAddressList();
		}
	}

	onAddItems(): void 
	{
		let data = {
			sample_type: this.sample_type,
			min_temperature: this.min_temperature,
			max_temperature: this.max_temperature,
			sample_quantity: this.sample_quantity,
			storage_type: this.storage_type,
			bar_code: this.bar_code
		}

		if(this.containsSameObject(data, this.OrderItems))
		{
			
			alert('Already Added');
		}
		else
		{
			this.OrderItems.push(data);
			this.Form.get('items').setValue(this.OrderItems);
		}

		this.sample_type = null;
		this.min_temperature = null;
		this.max_temperature = null;
		this.sample_quantity = null;
		this.storage_type = null;
		this.bar_code = null;
	}

	onEditItem(item, i): void
	{
		this.OrderItems.splice(i, 1);

		this.sample_type = item.sample_type;
		this.min_temperature = item.min_temperature;
		this.max_temperature = item.max_temperature;
		this.sample_quantity = item.sample_quantity;
		this.storage_type = item.storage_type;
		this.bar_code = item.bar_code;

		this.Form.get('items').setValue(this.OrderItems);
	}

	// onDeletItem(item, i): void
	// {
	// 	this.OrderItems.splice(i, 1);
	// 	this.Form.get('items').setValue(this.OrderItems);
	// }

	onDeletItem(item, i): void
	{
		if(this.isEditing)
		{
			let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
			let cm = dialogRef.componentInstance;
			cm.heading = 'Delete Item';
			cm.message = 'Are you sure to Delete Item?';
			cm.submitButtonText = 'Yes';
			cm.cancelButtonText = 'No';
			cm.type = 'ask';
			cm.methodType = 'get';
			cm.methodName = appConfig.base_url_slug + '/delete-orderitem?id='+ item.id;
			cm.showLoading = true;

			dialogRef.afterClosed().subscribe(result => {
				if(result)
				{
					this.OrderItems.splice(i, 1);
					this.Form.get('items').setValue(this.OrderItems);
				}
			})
		}
		else
		{
			this.OrderItems.splice(i, 1);
			this.Form.get('items').setValue(this.OrderItems);
		}
	}

	containsSameObject(obj: any, list: any) 
	{
		if(list.length == 0)
		{
			return false
		}

		for (var x in list) 
		{
			if (JSON.stringify(list[x]) === JSON.stringify(obj)) {
				return true;
			}
		}
	
		return false;
	}


	onPickupChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.pickUp = result[0];
		this.PickupLocation = result[0].latitude + ',' + result[0].longitude;

		this.getControlName('pickup_latitude').setValue(this.pickUp.latitude);
		this.getControlName('pickup_longitude').setValue(this.pickUp.longitude);
		this.getControlName('pickup_address').setValue(this.pickUp.address);
		this.getControlName('pickup_phone').setValue(this.pickUp.person_phone);
		this.getControlName('pickup_name').setValue(this.pickUp.person_name);
	}

	onDropoffChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.dropOff = result[0];
		this.DropoffLocation = result[0].latitude + ',' + result[0].longitude;

		this.getControlName('dropoff_latitude').setValue(this.dropOff.latitude);
		this.getControlName('dropoff_longitude').setValue(this.dropOff.longitude);
		this.getControlName('dropoff_address').setValue(this.dropOff.address);
		this.getControlName('dropoff_phone').setValue(this.dropOff.person_phone);
		this.getControlName('dropoff_name').setValue(this.dropOff.person_name);
	}

	onSelectDate(): void 
	{
		let oD = moment(this.orderDate).format('YYYY-MM-DD');
		this.getControlName('pickup_date').setValue(oD);
	}

	onSelectTime(): void 
	{
		let oT = moment(this.orderTime).format('HH:mm:ss');
		this.getControlName('pickup_time').setValue(oT);
	}

	onUrgency(type: any): void
	{
		this.getControlName('urgency').setValue(type);
	}

	onConfirmOrder(): void
	{
		let url = '';

		if (this.id == 'add')
		{
			url = appConfig.base_url_slug + '/add-orderwith-item';
		}
		else
		{
			url = appConfig.base_url_slug + '/update-orderwith-item';
		}
		console.log(this.Form.value);
		this.mainApiService.postData( url, this.Form.value).then(response => 
		{
			if (response.status == 200 || response.status === 201)
			{
				console.log(response);
				this.router.navigateByUrl('/main/orders/list/all');
			}
			else
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}
	
	onLocationBack()
	{
		window.history.back();
	}
}


