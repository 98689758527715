import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MainService, BaseLoaderService, PaginationService } from '../../services';
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';


@Component({
	selector: 'app-customers-list',
	templateUrl: './customers-list.component.html'
})
export class CustomersListComponent implements OnInit
{
	search: string;
	index:  any = 1;
	totalPages: number;
	pages: any;
	totalItems: any;
	currentPage:  any = 1;
	customersCount: any;
	Customers: any;
	id: any;
	searchTimer:any;
	perPage: number;
	pageSettings: any;
	sub: Subscription;
	type: any;
	sort_order: string = '';

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected paginationService: PaginationService, protected dialog: MatDialog)
	{
		this.search = '';
		this.Customers = [];
		this.perPage = 20;

		this.pageSettings = {
			name: null,
			paggination: null,
			search: null
		}
	}


	ngOnInit()
	{
		this.pageSettings = JSON.parse(localStorage.getItem('pageSettings'))
		if(this.pageSettings)
		{
			if(this.pageSettings.name != null && this.pageSettings.name == 'Customers')
			{
				this.currentPage = this.pageSettings.paggination;
				this.index = this.pageSettings.paggination;
				this.search = this.pageSettings.search;
			}
		}
		this.sub = this._route.params.subscribe(params => {
			this.type = params['type'];
				if (this.type)
				{
					this.getCustomersList(this.currentPage);
				}
		});
	}

	onSearch(): void
	{
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {

			this.getCustomersList(1);

		}, 800);
	}

	getCustomersList(index): void
	{
		this.Customers = [];

		let url = '';

		if(this.type == 'pending')
		{
			url = '/view-users?page=' + index + '&per_page=' + this.perPage + '&role_type=partner&user_status=0';
		}
		else if(this.type == 'registered')
		{
			url = '/view-users?page=' + index + '&per_page=' + this.perPage + '&role_type=partner&user_status=1';
		}
		else if(this.type == 'rejected')
		{
			url = '/view-users?page=' + index + '&per_page=' + this.perPage + '&role_type=partner&user_status=2';
		}
		else if(this.type == 'withoutcontract')
		{
			url = '/users-withoutcontract?page=' + index + '&per_page=' + this.perPage + '&role_type=partner';
		}
		else
		{
			url = '/view-users?page=' + index + '&per_page=' + this.perPage + '&role_type=partner&user_status=all';
		}

		if(this.search != '')
		{
			url = url + '&search=' + this.search;
		}
		if(this.sort_order != '')
		{
			url = url + '&sort_order=' + this.sort_order;
		}

		localStorage.setItem('pageSettings', JSON.stringify(
			{
				name: 'Customers',
				paggination: this.index,
				search: this.search
			}
        ));

		this.mainApiService.getList(appConfig.base_url_slug + url)
		.then(result => {
			if (result.status === 200  && result.data)
			{
				if(this.type == 'withoutcontract')
				{
					//  = result.data;
					result.data.forEach(element => {
						if(element.contracts.length == 0)
						{
							this.Customers.push(element);
						}
					});
				}
				else
				{
					this.Customers = result.data;
				}
				this.customersCount = result.pagination.count;
				this.currentPage = index;
				this.pages = this.paginationService.setPagination(this.customersCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
			else
			{
				this.Customers = [];
				this.customersCount = 0;
				this.currentPage = 1;
				this.pages = this.paginationService.setPagination(this.customersCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
		});
	}

	onContract(item, type): void
	{
		// localStorage.setItem('PartnerID', JSON.stringify(item.id));
		this.router.navigateByUrl('/main/customers/list/withoutcontract/' + type +'/'+ item.id);
	}

	onAddAddress(item): void
	{
		// localStorage.setItem('PartnerID', JSON.stringify(item.id));
		this.router.navigateByUrl('/main/customers/list/' + this.type +'/edit/'+ item.id + '/manage-address');
	}

	onViewContracts(item): void
	{
		this.router.navigateByUrl('/main/customers/list/' + this.type +'/contracts/'+ item.id);
	}

	onAccept(order, event)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Update Customer';
		cm.message = 'Are you sure to Accept Customer?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/update-userstatus?id='+ order.id +'&approved=1';
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			this.getCustomersList(1);
		})
	}

	onReject(order, event)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Update Customer';
		cm.message = 'Are you sure to Reject Customer?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/update-userstatus?id='+ order.id +'&approved=2';
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			this.getCustomersList(1);
		})
	}

	onEdit(item): void
	{
		localStorage.setItem('Customer', JSON.stringify(item));
		this.router.navigateByUrl('/main/customers/list/all/edit/' + item.id);
	}

	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getCustomersList(pageDate.page);
	}


	onLocationBack(): void
	{
		window.history.back();
	}

	onDelete(item :any)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Delete Partner';
		cm.message = 'Are you sure you want to delete this Partner?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/delete-user?id='+item.id;
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			if(result == true){
				this.getCustomersList(1);
			}
		});
	}
}
