import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MainService } from '../../services';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';


@Component({
	selector: 'app-customers-form',
	templateUrl: './customers-form.component.html'
})
export class CustomersFormComponent implements OnInit
{

	Form: FormGroup;
	sub: any;
	id: any;
	isEditing: boolean;
	customer: any;
	Cities: any[] = [];

	address: string = '';
	latitude: number = 24.7136;
	longitude: number = 46.6753;

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected fb: FormBuilder, protected dialog: MatDialog)
	{
		this.Form = this.fb.group({
			name: [null, [Validators.required]],
			contract_type: [null, [Validators.required]],
			email: [null, [Validators.required]],
			password: [null],
			phone: [null, [Validators.required]],
			city_id: [null, [Validators.required]],
			barcode_receiver: [null, [Validators.required]],
			barcode_sender: [null, [Validators.required]],
			role_type: ['partner'],
			current_role: ['admin'],

			address: [null],
			latitude: [null],
			longitude: [null],

		})
	}


	ngOnInit()
	{
		this.Form.get('password').valueChanges.subscribe(response => {
			if(response.length != 0)
			{
				this.Form.get('password').setValidators([Validators.required]);
			}
			if (response.length == 0)
			{
				this.Form.get('password').setErrors(null);
			}
		});

		this.sub = this._route.params.subscribe(params => {
			this.id = params['id'];
				if (this.id != 'add')
				{
					this.isEditing = true;
					this.Form.addControl('id', new FormControl(this.id));
					this.onViewData();
				}
				else
				{
					this.isEditing = false;
				}
		});

		this.Cities = JSON.parse(localStorage.getItem('Cities'));
	}

	getValue(name)
    {
        return this.Form.get(name);
	}

	onViewData(): void
	{
		let url = '/view-driver?id=' + this.id;
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			console.log(result)
			if (result.status === 200 && result.data) 
			{
				this.customer = result.data[0];
				this.Form.patchValue(this.customer);
			}
			else 
			{
				
			}
		});
	}

	onSelectAddress(e)
	{
		console.log('dsjhdj')
		if(e.formatted_address)
		{
			let split = e.latlng.split(',');
			this.Form.get('address').setValue(e.formatted_address);
			this.Form.get('latitude').setValue(split[0]);
			this.Form.get('longitude').setValue(split[1]);
		}
		else
		{
			// this.Form.get('address').setValue('No Address Found');
			// this.Form.get('latitude').setValue('0');
			// this.Form.get('longitude').setValue('0');
		}
	}


	onLocationBack(): void
	{
		this.router.navigateByUrl('/main/customers/list/all');
	}

	onSubmit(): void
	{
		let url = '';
		if(this.id == 'add')
		{
			url = '/register'
		}
		else
		{
			url = '/update-user';
		}
		this.mainApiService.postData(appConfig.base_url_slug + url, this.Form.value).then(response => {
			if (response.status == 200 || response.status === 201)
			{
				this.router.navigateByUrl('/main/customers/list/all');
			}
			else
			{
				console.log(response.error)
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}
}
