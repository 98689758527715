
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PaginationService } from 'src/app/services';
// import { Subscription } from 'rxjs';


// declare var $: any;


@Component({
	selector: 'app-assign-vehicle',
	templateUrl: 'assign-vehicle.component.html'
})
export class AssignVehicleComponent implements OnInit 
{
	Form: FormGroup;
	Vehicles: any = [];
	isEditing: any;
	driver: any;

	constructor(private mainApiService: MainService, protected fb: FormBuilder, protected dialog: MatDialog, protected dialogRef: MatDialogRef<AssignVehicleComponent>) 
	{
		this.Form = this.fb.group({
			driver_id: [null, Validators.required],
			vehicle_id: [null, Validators.required],
		})
	}

	ngOnInit() 
	{
		this.Form.get('driver_id').setValue(this.driver.id);
		if(this.driver.user)
		{
			this.Form.get('vehicle_id').setValue(this.driver.user.id);
		}

		this.getVehiclesList();
		// this.getAssignedVehiclesList(1);
	}

	getVehiclesList(): void
	{
		let url = '/view-vehicle';
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => {
			if (result.status === 200  && result.data)
			{
				this.Vehicles = result.data;
			}
			else
			{
				this.Vehicles = [];
			}
		});
	}

	onClose(): void
	{
		this.dialogRef.close(false);
	}

	// getDriversList(): void
	// {
	// 	let url = '/all-drivers';

	// 	this.mainApiService.getList(appConfig.base_url_slug + url).then(result => {
	// 		if (result.status === 200  && result.data)
	// 		{
	// 			this.Drivers = result.data;
	// 			if (this.DriverID != 'add')
	// 			{
	// 				this.Form.get('driver_id').setValue(parseInt(this.DriverID));
	// 			}
	// 		}
	// 		else
	// 		{
	// 			this.Drivers = [];
	// 		}
	// 	});
	// }

	// onLocationBack(): void
	// {
	// 	window.history.back();
	// }

	onSubmit(): void
	{
		let url = '';
		if(this.isEditing)
		{
			url = '/update-assginvehicle?vehicle_id='+ this.Form.get('vehicle_id').value +'&driver_id=' + this.Form.get('driver_id').value;
		}
		else
		{
			url = '/assign-vehicle?vehicle_id='+ this.Form.get('vehicle_id').value +'&driver_id=' + this.Form.get('driver_id').value;
		}

		this.mainApiService.getList(appConfig.base_url_slug + url).then(response => {
			if (response.status == 200 || response.status === 201)
			{
				this.isEditing = false;
				this.dialogRef.close(true);
			}
			else if (response.status == 208)
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
			else
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}

	// getAssignedVehiclesList(index): void
	// {
	// 	let url = '/view-assginvehicle?page=' + index + '&per_page=' + this.perPage;

	// 	if(this.search != '')
	// 	{
	// 		url = url + '&search=' + this.search;
	// 	}

	// 	this.mainApiService.getList(appConfig.base_url_slug + url).then(result => {
	// 		if (result.status === 200  && result.data)
	// 		{
	// 			this.AssignedVehicles = result.data;
	// 			// this.assignedVehiclesCount = result.pagination.count;
	// 			// this.currentPage = index;
	// 			// this.pages = this.paginationService.setPagination(this.assignedVehiclesCount, index, this.perPage);
	// 			// this.totalPages = this.pages.totalPages;
	// 		}
	// 		else
	// 		{
	// 			this.Vehicles = [];
	// 			// this.assignedVehiclesCount = 0;
	// 			// this.currentPage = 1;
	// 			// this.pages = this.paginationService.setPagination(this.assignedVehiclesCount, index, this.perPage);
	// 			// this.totalPages = this.pages.totalPages;
	// 		}
	// 	});
	// }

	// onEdit(item): void
	// {
	// 	let data = {
	// 		driver_id: item.id,
	// 		vehicle_id: item.id,
	// 	}
	// 	this.Form.setValue(data);
	// 	this.isEditing = true;
	// }

	// setPage(pageDate: any)
	// {
	// 	this.currentPage = pageDate.page;
	// 	this.perPage = pageDate.perPage;
	// 	this.index = this.currentPage;
	// 	this.getAssignedVehiclesList(pageDate.page);
	// }
}


