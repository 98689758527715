import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MainService, BaseLoaderService, PaginationService } from '../../services';
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { AssignOrderComponent } from './assign-order.component';
import { OrderDetailComponent } from './order_detail.component';


@Component({
	selector: 'app-orders-list',
	templateUrl: './orders-list.component.html'
})
export class OrdersListComponent implements OnInit
{
	search: string;
	index:  any = 1;
	totalPages: number;
	pages: any;
	totalItems: any;
	currentPage:  any = 1;
	ordersCount: any;
	Orders: any;
	id: any;
	searchTimer:any;
	perPage: number;
	pageSettings: any;
	sub: Subscription;
	type: any;
	urgency: any = 'all';
	sort_order: string = '';

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected paginationService: PaginationService, protected dialog: MatDialog)
	{
		this.search = '';
		this.Orders = [];
		this.perPage = 20;

		this.pageSettings = {
			name: null,
			paggination: null,
			search: null
		}
	}


	ngOnInit()
	{
		this.pageSettings = JSON.parse(localStorage.getItem('pageSettings'))
		if(this.pageSettings)
		{
			if(this.pageSettings.name != null && this.pageSettings.name == 'Orders')
			{
				this.currentPage = this.pageSettings.paggination;
				this.index = this.pageSettings.paggination;
				this.search = this.pageSettings.search;
			}
		}
		this.sub = this._route.params.subscribe(params => {
			this.type = params['type'];
				if (this.type)
				{
					this.getOrdersList(1);
				}
		});
	}

	onSearch(): void
	{
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this.getOrdersList(1);
		}, 800);
	}

	getOrdersList(index): void
	{
		let url = '';
		if(this.type == 'pending')
		{
			url = '/all-orders' + '?page=' + index + '&status=pending';
		}
		else if(this.type == 'completed')
		{
			url = '/all-orders' + '?page=' + index + '&status=completed';
		}
		else
		{
			url = '/all-orders' + '?page=' + index + '&status=all';
		}

		url = url  + '&per_page=' + this.perPage  + '&urgency=' + this.urgency;
		
		if(this.search != '')
		{
			url = url + '&search=' + this.search;
		}

		if(this.sort_order != '')
		{
			url = url + '&sort_order=' + this.sort_order;
		}

		localStorage.setItem('pageSettings', JSON.stringify(
			{
				name: 'Orders',
				paggination: this.index,
				search: this.search
			}
        ));

		this.mainApiService.getList(appConfig.base_url_slug + url)
		.then(result => {
			if (result.status === 200  && result.data)
			{
				this.Orders = result.data;
				this.ordersCount = result.pagination.count;
				this.currentPage = index;
				this.pages = this.paginationService.setPagination(this.ordersCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
			}
			else
			{
				this.Orders = [];
				this.ordersCount = 0;
				this.currentPage = 1;
				this.pages = this.paginationService.setPagination(this.ordersCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
			}
		});
	}

	onUrgencyChange(event)
	{
		this.urgency = event.value;
		this.getOrdersList(1);
	}

	getLength(str: string): string
	{
		if(str == null)
		{
			return 'N/A';
		}
		if(str.length > 17)
		{
			let st = str.substring(0, 14);
			return st + '..';
		}
		else
		{
			return str;
		}
	}

	onAcceptOrder(order, event)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Update Order';
		cm.message = 'Are you sure to Accept Order?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/update-order-status?order_id='+ order.id +'&status=accept';
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			this.getOrdersList(1);
		})
	}

	onRejectOrder(order, event)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Update Order';
		cm.message = 'Are you sure to Reject Order?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/update-order-status?order_id='+ order.id +'&status=reject';
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			this.getOrdersList(1);
		})
	}

	onEditOrder(order, event)
	{
		event.stopPropagation();
		this.router.navigateByUrl('/main/orders/list/'+ this.type +'/edit/' + order.id);
	}


	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getOrdersList(pageDate.page);
	}

	onLocationBack(): void
	{
		window.history.back();
	}

	onTrack(id:any)
	{
		this.router.navigateByUrl('/main/orders/list/'+ this.type +'/track/' + id);
	}

	onAssignOrder(order, event, status): void
	{
		event.stopPropagation();
		let dialofRef = this.dialog.open(AssignOrderComponent, { autoFocus: false, disableClose: true});
		dialofRef.componentInstance.order = order;
		if(status == 'assign')
		{
			dialofRef.componentInstance.isEditing = false;
		}
		else
		{
			dialofRef.componentInstance.isEditing = true;
		}
		dialofRef.afterClosed().subscribe(result => {
			if(result)
			{
				this.getOrdersList(1);
			}
		})
	}

	onOrderDetail(order, event): void
	{
		event.stopPropagation();
		let dialofRef = this.dialog.open(OrderDetailComponent, { autoFocus: false, disableClose: true});
		dialofRef.componentInstance.order = order;
		// dialofRef.afterClosed().subscribe(result => {
		// 	if(result)
		// 	{
		// 		this.getOrdersList(1);
		// 	}
		// })
	}
}
