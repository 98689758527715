import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { PaginationService } from "../../services/pagination.service";
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';


declare var $: any;


@Component({
    selector: 'contracts_list',
    templateUrl: 'contracts_list.component.html',
    styleUrls: ['contracts_list.component.scss'],
})
export class ContractsListComponent implements OnInit {


    search: string;
	index:  any = 1;
	totalPages: number;
	pages: any;
	totalItems: any;
	currentPage:  any = 1;
	contractsCount: any;
	Contracts: any = [];
	id: any;
	searchTimer:any;
	perPage: number;
	pageSettings: any;
	partner_id: string;
	Customers: any = [];
	sort_order: string;
	sub: Subscription;
	customer_type: any;

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected paginationService: PaginationService, protected dialog: MatDialog)
	{
		this.search = '';
		this.sort_order = '';
		this.Contracts = [];
		this.perPage = 20;

		this.pageSettings = {
			name: null,
			paggination: null,
			search: null
		}
	}


	ngOnInit()
	{
		this.pageSettings = JSON.parse(localStorage.getItem('pageSettings'))
		if(this.pageSettings)
		{
			if(this.pageSettings.name != null && this.pageSettings.name == 'Contracts')
			{
				this.currentPage = this.pageSettings.paggination;
				this.index = this.pageSettings.paggination;
				this.search = this.pageSettings.search;
			}
		}

		this.sub = this._route.params.subscribe(params => {
			this.partner_id = params['partner_id'];
			this.customer_type = params['type'];
			if (this.partner_id)
			{
				this.getContractsList(1);
			}
		});

		// this.getCustomersList();
		// this.getContractsList(1);
	}

	onSearch(): void
	{
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {

			this.getContractsList(1);

		}, 300);
	}

	// getCustomersList(): void 
	// {
	// 	let url = '/view-users?page=' + 1 + '&per_page=' + this.perPage + '&role_type=partner&user_status=all';
	// 	this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
	// 	{
	// 		if (result.status === 200 && result.data) 
	// 		{
	// 			this.Customers = result.data;
	// 		}
	// 		else 
	// 		{
	// 			this.Customers = [];
	// 		}
	// 	});
	// }

	onContract(type): void
	{
		// localStorage.setItem('PartnerID', JSON.stringify(item.id));
		this.router.navigateByUrl('/main/customers/list/' + this.customer_type + '/' + type +'/'+ this.partner_id);
	}

	getCustomer(id): string
	{
		const result = this.Customers.filter(add => id === add.id);
		if(result.length > 0)
		{
			return result[0].name;
		}
		return 'N/A';
	}

	onCronContract(): void
	{
		this.mainApiService.getList(appConfig.base_url_slug + '/add-cron-contracts').then(result => {
			if (result.status === 200  && result.data)
			{
				console.log(result);
			}
			else
			{
			}
		});
	}

	getContractsList(index): void
	{

		let url = '/view-user-contracts?page=' + index + '&per_page=' + this.perPage + '&id=' + this.partner_id;

		if(this.search != '')
		{
			url = url + '&search=' + this.search;
		}

		if(this.sort_order != '')
		{
			url = url + '&sort_order=' + this.sort_order;
		}

		localStorage.setItem('pageSettings', JSON.stringify(
			{
				name: 'Contracts',
				paggination: this.index,
				search: this.search
			}
        ));

		this.mainApiService.getList(appConfig.base_url_slug + url)
		.then(result => {
			if (result.status === 200  && result.data)
			{
				this.Contracts = result.data;
				this.contractsCount = result.pagination.count;
				this.currentPage = index;
				this.pages = this.paginationService.setPagination(this.contractsCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
			else
			{
				this.Contracts = [];
				this.contractsCount = 0;
				this.currentPage = 1;
				this.pages = this.paginationService.setPagination(this.contractsCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
		});
	}

	onEdit(item): void
	{
		this.router.navigateByUrl('/main/customers/list/' + this.customer_type + '/contracts/' + this.partner_id + '/edit/' + item.id);
	}

	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getContractsList(pageDate.page);
	}


	onLocationBack(): void
	{
		window.history.back();
	}

	onDelete(item :any)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Delete Contract';
		cm.message = 'Are you sure you want to delete this contract?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/delete-contract?id='+item.id;
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			if(result == true){
				this.getContractsList(1);
			}
		});
	}
}
