import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MainService } from '../../services';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-vehicles-form',
	templateUrl: './vehicles-form.component.html'
})
export class VehiclesFormComponent implements OnInit, OnDestroy
{
	Form: FormGroup;
	sub: Subscription;
	id: any;
	customer: any;
	isEditing: boolean;

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected fb: FormBuilder, protected dialog: MatDialog)
	{
		this.Form = this.fb.group({
			name: [null],
			plates: [null],
			total_freezers: [null],
			freezers: this.fb.array([]),
		})
	}


	ngOnInit()
	{
		this.sub = this._route.params.subscribe(params => {
			this.id = params['id'];
				if (this.id != 'add')
				{
					this.isEditing = true;
					this.Form.addControl('id', new FormControl(this.id));
					this.getVehicle(this.id);
				}
				else
				{
					this.isEditing = false;
				}
		});
	}

	getVehicle(idx: number): void
	{
		let url = '/vehicle-details?vehicle_id=' + idx;

		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => {
			if (result.status === 200  && result.data)
			{
				this.Form.patchValue(result.data);

				this.Form.get('total_freezers').setValue(result.data.total_freezers + '')

				result.data.freezers.forEach(element => {
					let fdForm = this.fb.group({
						bar_code: new FormControl(element.bar_code, Validators.required),
						freezer_id: new FormControl(element.id, Validators.required),
						min_temperature: new FormControl(element.min_temperature, Validators.required),
						max_temperature: new FormControl(element.max_temperature, Validators.required),
					});
			
					this.freezers.push(fdForm);
				});
			}
			else
			{
				
			}
		});
	}


	getValue(name)
    {
        return this.Form.get(name);
	}

	onLocationBack(): void
	{
		window.history.back();
	}

	get freezers(): FormArray 
	{
		return this.Form.get('freezers') as FormArray;
	}

	onAddMoreFreezers() 
	{
		let fdForm = this.fb.group({
			bar_code: new FormControl('', Validators.required),
			min_temperature: new FormControl('', Validators.required),
			max_temperature: new FormControl('', Validators.required),
		});

		this.freezers.push(fdForm);
	};

	onDeleteFreezer(i, item): void
	{
		if(this.isEditing)
		{
			let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
			let cm = dialogRef.componentInstance;
			cm.heading = 'Delete Freezer';
			cm.message = 'Are you sure to Delete Freezer?';
			cm.submitButtonText = 'Yes';
			cm.cancelButtonText = 'No';
			cm.type = 'ask';
			cm.methodType = 'get';
			cm.methodName = appConfig.base_url_slug + '/delete-freezer?freezer_id='+ item.value.freezer_id;
			cm.showLoading = true;

			dialogRef.afterClosed().subscribe(result => {
				if(result)
				{
					this.freezers.removeAt(i);
				}
			})
		}
		else
		{
			this.freezers.removeAt(i);
		}
	}

	onSubmit(): void
	{
		let url = '';
		if(this.isEditing)
		{
			this.Form.addControl('vehicle_id', new FormControl(this.id));
			url = '/update-vehicle-details';
		}
		else
		{
			url = '/add-vehicle';
		}

		this.mainApiService.postData(appConfig.base_url_slug + url, this.Form.value).then(response => {
			if (response.status == 200 || response.status === 201)
			{
				this.router.navigateByUrl('/main/vehicles/list')
			}
			else
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}

	ngOnDestroy(): void
	{
		this.sub.unsubscribe();
	}
}
