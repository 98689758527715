
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import {FormGroup, Validators, FormBuilder, FormControl, FormArray} from "@angular/forms";
import {MatDialog} from "@angular/material";
import { appConfig } from 'src/config';
import { Subscription } from 'rxjs';
import { AlertDialog } from 'src/app/lib';
import * as moment from 'moment';


@Component({
	selector: 'edit_contract',
	templateUrl: 'edit_contract.component.html'
})
export class EditContractsComponent implements OnInit {


	Form: FormGroup;
	sub: Subscription;
	id: any;
	customer: any;
	isEditing: boolean;
    perPage: string;
    
	Customers: any = [];
    Addresses: any;
    
    PickupLocation: string;
    
	dropOff: any = {
		address: 'Select Address',
		person_name: 'Select Address',
		person_phone: 'Select Address'
	};
	pickUp: any = {
		address: 'Select Address',
		person_name: 'Select Address',
		person_phone: 'Select Address'
	};
	dropoff: any = '';
	pickup: any = '';
	DropoffLocation: string;
	ContractItems: any = [];
	onlyTime: any;
	
	currentDate: Date = new Date();

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected fb: FormBuilder, protected dialog: MatDialog)
	{
		this.Form = this.fb.group({
			contract_type: ['schedule'],
			partner_id: [null],
			interval: [null],
            discount: [null],
            pickup_latitude: [null],
            pickup_longitude: [null],
            pickup_address: [null],
            dropoff_latitude: [null],
            dropoff_longitude: [null],
            dropoff_address: [null],
            quantity: [null],
            price: [null],
            pickup_units: [null],
            pickup_time: [null],
		});

		this.Form.get('partner_id').valueChanges.subscribe(result => {
            if(result)
            {
                this.getAddressList();
            }
		})
	}


	ngOnInit()
	{
		this.sub = this._route.params.subscribe(params => {
			this.id = params['id'];
            if (this.id != 'add')
            {
                this.isEditing = true;
                this.getContract();

            }
            else
            {
                this.isEditing = false;
            }
		});

		this.getCustomersList();
		this.getAddressList();
	}

	getCustomersList(): void 
	{
		let url = '/view-users?page=' + 1 + '&per_page=' + 500 + '&role_type=partner&user_status=1';
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Customers = result.data;
			}
			else 
			{
				this.Customers = [];
			}
		});
    }

	getAddressList(): void 
	{
		let url = '/view-location?id=' + this.Form.get('partner_id').value;
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Addresses = result.data;
			}
			else 
			{
				this.Addresses = [];
			}
		});
	}

    getContract(): void 
	{
		let url = '/view-contract?id=' + this.id;
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
                let contract = result.data;
                console.log(contract);

                this.Form.patchValue(contract);

                this.onlyTime = new Date(contract.pickup_time);

                this.getAddressList();
			}
			else 
			{
			}
		});
	}


	onLocationBack(): void
	{
		window.history.back();
	}

	getControlName(name) 
    { 
        return this.Form.get(name); 
	}

	getControlValue(name) 
    { 
        return this.Form.get(name).value; 
	}

	
	onPickupChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.pickUp = result[0];
		this.PickupLocation = result[0].latitude + ',' + result[0].longitude;

		this.Form.get('pickup_latitude').setValue(this.pickUp.latitude);
		this.Form.get('pickup_longitude').setValue(this.pickUp.longitude);
		this.Form.get('pickup_address').setValue(this.pickUp.address);
	}

	onDropoffChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.dropOff = result[0];
		this.DropoffLocation = result[0].latitude + ',' + result[0].longitude;

		this.Form.get('dropoff_latitude').setValue(this.dropOff.latitude);
		this.Form.get('dropoff_longitude').setValue(this.dropOff.longitude);
		this.Form.get('dropoff_address').setValue(this.dropOff.address);
	}

	onSelectTime(): void 
	{
		let oT = moment(this.onlyTime).format('HH:mm:ss');
		this.Form.get('pickup_time').setValue(oT);
	}


	onSubmit(): void
	{
		let url = '/update-contract/' + this.id;
        
		this.mainApiService.postData(appConfig.base_url_slug + url, this.Form.value).then(response => {
			if (response.status == 200 || response.status === 201)
			{
				// this.router.navigateByUrl('/main/contracts/list')
				this.onLocationBack();
			}
			else
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}

	ngOnDestroy(): void
	{
		this.sub.unsubscribe();
	}
}
