﻿import { Component, OnInit, EventEmitter, Input, Output, NgZone, ViewEncapsulation, OnDestroy, ElementRef, OnChanges  } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';

declare var google: any;

@Component({
    selector: 'location-view',
    templateUrl: './location-view.component.html',
    styleUrls: ['./location-view.component.css']
})
export class LocationViewComponent implements OnInit, OnChanges
{
    @Input()
    initialLocation: string;
    initialZoom: number = 10;
    currentLocation: {lat: number, lng: number};

    map: any;
    marker: any;

    constructor(private elRef: ElementRef, private _loader: MapsAPILoader, private _zone: NgZone, public gMaps: GoogleMapsAPIWrapper)
    {
        this.map = null;
        this.marker = null;
    }

    ngAfterViewInit()
    {

    }

    ngOnChanges(): void
    {
        let loc = {lat: 24.7136, lng: 46.6753};

        if(this.initialLocation != null)
        {
            let coords = this.initialLocation.split(',');
            if (coords.length == 2)
            {
                loc.lat = +coords[0];
                loc.lng = +coords[1];
            }
        }

        this.currentLocation = loc;

        this.marker =
        {
            lat: this.currentLocation.lat,
            lng: this.currentLocation.lng,
            label: '',
        }

        this.gMaps.panTo(this.currentLocation);
    }

    ngOnInit(): void
    {
        let loc = {lat: 0, lng: 0};

        if(this.initialLocation != null)
        {
            let coords = this.initialLocation.split(',');
            if (coords.length == 2)
            {
                loc.lat = +coords[0];
                loc.lng = +coords[1];
            }
        }

        this.currentLocation = loc;

        this.marker =
        {
            lat: this.currentLocation.lat,
            lng: this.currentLocation.lng,
            label: '',
        }
    }
}
