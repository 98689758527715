import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MainService, BaseLoaderService, PaginationService } from '../../services';
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { VehicleDetailComponent } from './vehicle_detail.component';


@Component({
	selector: 'app-vehicles-list',
	templateUrl: './vehicles-list.component.html'
})
export class VehiclesListComponent implements OnInit
{
	search: string;
	index:  any = 1;
	totalPages: number;
	pages: any;
	totalItems: any;
	currentPage:  any = 1;
	vehiclesCount: any;
	Vehicles: any;
	id: any;
	searchTimer:any;
	perPage: number;
	pageSettings: any;
	sort_order: string = '';

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected paginationService: PaginationService, protected dialog: MatDialog)
	{
		this.search = '';
		this.Vehicles = [];
		this.perPage = 20;

		this.pageSettings = {
			name: null,
			paggination: null,
			search: null
		}
	}


	ngOnInit()
	{
		this.pageSettings = JSON.parse(localStorage.getItem('pageSettings'))
		if(this.pageSettings)
		{
			if(this.pageSettings.name != null && this.pageSettings.name == 'Vehicles')
			{
				this.currentPage = this.pageSettings.paggination;
				this.index = this.pageSettings.paggination;
				this.search = this.pageSettings.search;
			}
		}
		this.getVehiclesList(this.currentPage);
	}

	onSearch(): void
	{
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {

			this.getVehiclesList(1);

		}, 800);
	}

	getVehiclesList(index): void
	{

		let url = '/view-vehicle?page=' + index + '&per_page=' + this.perPage;

		if(this.search != '')
		{
			url = url + '&search=' + this.search;
		}

		if(this.sort_order != '')
		{
			url = url + '&sort_order=' + this.sort_order;
		}

		localStorage.setItem('pageSettings', JSON.stringify(
			{
				name: 'Vehicles',
				paggination: this.index,
				search: this.search
			}
        ));

		this.mainApiService.getList(appConfig.base_url_slug + url)
		.then(result => {
			if (result.status === 200  && result.data)
			{
				this.Vehicles = result.data;
				this.vehiclesCount = result.pagination.count;
				this.currentPage = index;
				this.pages = this.paginationService.setPagination(this.vehiclesCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
			else
			{
				this.Vehicles = [];
				this.vehiclesCount = 0;
				this.currentPage = 1;
				this.pages = this.paginationService.setPagination(this.vehiclesCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
		});
	}

	onEdit(item): void
	{
		localStorage.setItem('Customer', JSON.stringify(item));
		this.router.navigateByUrl('/main/vehicles/list/edit/' + item.id);
	}

	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getVehiclesList(pageDate.page);
	}


	onLocationBack(): void
	{
		window.history.back();
	}

	onDelete(item :any)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Delete Vehicle';
		cm.message = 'Are you sure you want to delete this Vehicle?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/delete-vehicle?id='+item.id;
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			if(result == true){
				this.getVehiclesList(1);
			}
		});
	}

	onVehicleDetail(vehicle): void
	{
		let dialofRef = this.dialog.open(VehicleDetailComponent, { autoFocus: false, disableClose: true});
		dialofRef.componentInstance.vehicle = vehicle;
		// dialofRef.afterClosed().subscribe(result => {
		// 	if(result)
		// 	{
		// 		this.getOrdersList(1);
		// 	}
		// })
	}
}
