import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MainService, BaseLoaderService, PaginationService } from '../../services';
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { AssignVehicleComponent } from '../vehicles';


@Component({
	selector: 'app-drivers-list',
	templateUrl: './drivers-list.component.html'
})
export class DriversListComponent implements OnInit
{
	search: string;
	index:  any = 1;
	totalPages: number;
	pages: any;
	totalItems: any;
	currentPage:  any = 1;
	driversCount: any;
	Drivers: any;
	id: any;
	searchTimer:any;
	perPage: number;
	pageSettings: any;
	sort_order: string = '';

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected paginationService: PaginationService, protected dialog: MatDialog)
	{
		this.search = '';
		this.Drivers = [];
		this.perPage = 20;

		this.pageSettings = {
			name: null,
			paggination: null,
			search: null
		}
	}


	ngOnInit()
	{
		this.pageSettings = JSON.parse(localStorage.getItem('pageSettings'))
		if(this.pageSettings)
		{
			if(this.pageSettings.name != null && this.pageSettings.name == 'Drivers')
			{
				this.currentPage = this.pageSettings.paggination;
				this.index = this.pageSettings.paggination;
				this.search = this.pageSettings.search;
			}
		}
		this.getDriversList(this.currentPage);
	}

	onSearch(): void
	{
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {

			this.getDriversList(1);

		}, 800);
	}

	getDriversList(index): void
	{

		let url = '/all-drivers?page=' + index + '&per_page=' + this.perPage;

		if(this.search != '')
		{
			url = url + '&search=' + this.search;
		}

		if(this.sort_order != '')
		{
			url = url + '&sort_order=' + this.sort_order;
		}

		localStorage.setItem('pageSettings', JSON.stringify(
			{
				name: 'Drivers',
				paggination: this.index,
				search: this.search
			}
        ));

		this.mainApiService.getList(appConfig.base_url_slug + url)
		.then(result => {
			if (result.status === 200  && result.data)
			{
				this.Drivers = result.data;
				this.driversCount = result.pagination.count;
				this.currentPage = index;
				this.pages = this.paginationService.setPagination(this.driversCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
			else
			{
				this.Drivers = [];
				this.driversCount = 0;
				this.currentPage = 1;
				this.pages = this.paginationService.setPagination(this.driversCount, index, this.perPage);
				this.totalPages = this.pages.totalPages;
				// this.loaderService.setLoading(false);
			}
		});
	}

	onEdit(item): void
	{
		localStorage.setItem('Customer', JSON.stringify(item));
		this.router.navigateByUrl('/main/drivers/list/edit/' + item.id);
	}

	// onAssignDriver(item): void
	// {
	// 	this.router.navigateByUrl('/main/drivers/list/edit/' + item.id + '/assign' );
	// }

	onAssignDriver(order, status): void
	{
		let dialofRef = this.dialog.open(AssignVehicleComponent, { autoFocus: false, disableClose: true});
		dialofRef.componentInstance.driver = order;
		if(status == 'assign')
		{
			dialofRef.componentInstance.isEditing = false;
		}
		else
		{
			dialofRef.componentInstance.isEditing = true;
		}
		dialofRef.afterClosed().subscribe(result => {
			if(result)
			{
				this.getDriversList(1);
			}
		})
	}

	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getDriversList(pageDate.page);
	}


	onLocationBack(): void
	{
		window.history.back();
	}

	
	onDelete(item :any)
	{
		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Delete Driver';
		cm.message = 'Are you sure you want to delete this Driver?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/delete-user?id='+item.id;
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			if(result == true){
				this.getDriversList(1);
			}
		});
	}
}
