
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import {FormGroup, Validators, FormBuilder, FormControl, FormArray} from "@angular/forms";
import {MatDialog} from "@angular/material";
import { appConfig } from 'src/config';
import { Subscription } from 'rxjs';
import { AlertDialog } from 'src/app/lib';
import * as moment from 'moment';


@Component({
	selector: 'contracts_form',
	templateUrl: 'contracts_form.component.html'
})
export class ContractsFormComponent implements OnInit {


	Form: FormGroup;
	sub: Subscription;
	// id: any;
	customer: any;
	// isEditing: boolean;
	perPage: string;
	Customers: any = [];
	Addresses: any;
	PickupLocation: string;
	dropOff: any = {
		address: 'Select Address',
		person_name: 'Select Address',
		person_phone: 'Select Address'
	};
	pickUp: any = {
		address: 'Select Address',
		person_name: 'Select Address',
		person_phone: 'Select Address'
	};
	dropoff: any = '';
	pickup: any = '';
	DropoffLocation: string;
	ContractItems: any = [];
	onlyTime: any;
	pickup_latitude: any;
	pickup_longitude: any;
	pickup_address: any;
	dropoff_latitude: any;
	dropoff_longitude: any;
	dropoff_address: any;
	quantity: any;
	price: any;
	pickup_units: any;
	pickup_time: any;
	currentDate: Date = new Date();
	PartnerID: any = 'add';

	pickup_phone: any = '';
	pickup_name: any = '';
	dropoff_phone: any = '';
	dropoff_name: any = '';
	pickup_date: string;
	onlyDate: any;

	constructor(protected router: Router, protected _route: ActivatedRoute, protected mainApiService: MainService, protected fb: FormBuilder, protected dialog: MatDialog)
	{
		this.Form = this.fb.group({
			contract_type: ['schedule'],
			partner_id: [null],
			interval: [null],
			discount: [null],

			contracts: [],
		});

		// this.PartnerID = JSON.parse(localStorage.getItem('PartnerID'));
		// this.Form.get('partner_id').patchValue(this.PartnerID);

		this.Form.get('partner_id').valueChanges.subscribe(result => {
			this.getAddressList();
		})
	}


	ngOnInit()
	{
		this.sub = this._route.params.subscribe(params => {
			this.PartnerID = params['partner_id'];
				if (this.PartnerID != 'add')
				{
					// this.isEditing = true;
					
					// this.getVehicle(this.id);
				}
				else
				{
					// this.isEditing = false;
				}
		});

		this.getCustomersList();
		this.getAddressList();
	}

	getCustomersList(): void 
	{
		let url = '/view-users?page=' + 1 + '&per_page=' + 500 + '&role_type=partner&user_status=1';
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Customers = result.data;
				if (this.PartnerID != 'add')
				{
					this.Form.get('partner_id').setValue(parseInt(this.PartnerID));
				}
			}
			else 
			{
				this.Customers = [];
			}
		});
	}

	getAddressList(): void 
	{
		let url = '/view-location?id=' + this.Form.get('partner_id').value;
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Addresses = result.data;
			}
			else 
			{
				this.Addresses = [];
			}
		});
	}


	getState(): boolean
	{
		if(this.onlyTime == '' || this.onlyTime == null) return true;
		if(this.pickup_latitude == '' || this.pickup_latitude == null) return true;
		if(this.pickup_longitude == '' || this.pickup_longitude == null) return true;
		if(this.pickup_address == '' || this.pickup_address == null) return true;
		if(this.dropoff_latitude == '' || this.dropoff_latitude == null) return true;
		if(this.dropoff_longitude == '' || this.dropoff_longitude == null) return true;
		if(this.dropoff_address == '' || this.dropoff_address == null) return true;
		if(this.quantity == '' || this.quantity == null) return true;
		if(this.price == '' || this.price == null) return true;
		if(this.pickup_units == '' || this.pickup_units == null) return true;
		if(this.pickup_time == '' || this.pickup_time == null) return true;

		if(this.pickup_phone == '' || this.pickup_phone == null) return true;
		if(this.pickup_name == '' || this.pickup_name == null) return true;
		if(this.dropoff_phone == '' || this.dropoff_phone == null) return true;
		if(this.dropoff_name == '' || this.dropoff_name == null) return true;
	}

	getValue(name)
    {
        return this.Form.get(name);
	}

	onLocationBack(): void
	{
		window.history.back();
	}

	get contracts(): FormArray 
	{
		return this.Form.get('contracts') as FormArray;
	}

	onAddMore(): void 
	{
		let data = {
			pickup_latitude: this.pickup_latitude,
			pickup_longitude: this.pickup_longitude,
			pickup_address: this.pickup_address,
			dropoff_latitude: this.dropoff_latitude,
			dropoff_longitude: this.dropoff_longitude,
			dropoff_address: this.dropoff_address,
			quantity: this.quantity,
			price: this.price,
			pickup_units: this.pickup_units,
			pickup_time: this.pickup_time,
			pickup_date: this.pickup_date,

			pickup_phone: this.pickup_phone,
			pickup_name: this.pickup_name,
			dropoff_phone: this.dropoff_phone,
			dropoff_name: this.dropoff_name,
		}

		if(this.containsSameObject(data, this.ContractItems))
		{
			
			alert('Already Added');
		}
		else
		{
			this.ContractItems.push(data);
			this.Form.get('contracts').setValue(this.ContractItems);
		}

		this.pickup = null;
		this.dropoff = null;
		this.onlyTime = null;
		this.onlyDate = null;
		this.pickup_latitude = null;
		this.pickup_longitude = null;
		this.pickup_address = null;
		this.dropoff_latitude = null;
		this.dropoff_longitude = null;
		this.dropoff_address = null;
		this.quantity = null;
		this.price = null;
		this.pickup_units = null;
		this.pickup_time = null;
		this.pickup_date = null;

		this.pickup_phone = null;
		this.pickup_name = null;
		this.dropoff_phone = null;
		this.dropoff_name = null;
	}

	onEditItem(item, i): void
	{
		this.ContractItems.splice(i, 1);

		this.pickup_latitude = item.pickup_latitude,
		this.pickup_longitude = item.pickup_longitude,
		this.pickup_address = item.pickup_address,
		this.dropoff_latitude = item.dropoff_latitude,
		this.dropoff_longitude = item.dropoff_longitude,
		this.dropoff_address = item.dropoff_address,
		this.quantity = item.quantity,
		this.price = item.price,
		this.pickup_units = item.pickup_units,
		this.pickup_time = item.pickup_time,
		this.pickup_date = item.pickup_date,

		this.pickup_phone = item.pickup_phone;
		this.pickup_name = item.pickup_name;
		this.dropoff_phone = item.dropoff_phone;
		this.dropoff_name = item.dropoff_name;

		this.Form.get('contracts').setValue(this.ContractItems);
	}

	onDeletItem(item, i): void
	{
		this.ContractItems.splice(i, 1);
		this.Form.get('contracts').setValue(this.ContractItems);
	}

	containsSameObject(obj: any, list: any) 
	{
		if(list.length == 0)
		{
			return false
		}

		for (var x in list) 
		{
			if (JSON.stringify(list[x]) === JSON.stringify(obj)) {
				return true;
			}
		}
	
		return false;
	}

	getControlName(name) 
    { 
        return this.Form.get(name); 
	}

	getControlValue(name) 
    { 
        return this.Form.get(name).value; 
	}

	
	onPickupChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.pickUp = result[0];
		this.PickupLocation = result[0].latitude + ',' + result[0].longitude;

		this.pickup_latitude = this.pickUp.latitude;
		this.pickup_longitude = this.pickUp.longitude;
		this.pickup_address = this.pickUp.address;
		this.pickup_phone = this.pickUp.person_phone;
		this.pickup_name = this.pickUp.person_name;
	}

	onDropoffChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.dropOff = result[0];
		this.DropoffLocation = result[0].latitude + ',' + result[0].longitude;

		this.dropoff_latitude = this.dropOff.latitude;
		this.dropoff_longitude = this.dropOff.longitude;
		this.dropoff_address = this.dropOff.address;

		this.dropoff_phone = this.dropOff.person_phone;
		this.dropoff_name = this.dropOff.person_name;
	}

	onSelectTime(): void 
	{
		let oT = moment(this.onlyTime).format('HH:mm:ss');
		this.pickup_time = oT;
	}

	onSelectDate(): void 
	{
		let oT = moment(this.onlyDate).format('YYYY-MM-DD');
		this.pickup_date = oT;
	}

	onSubmit(): void
	{
		let url = '';
		// if(this.isEditing)
		// {
		// 	this.Form.addControl('contract_id', new FormControl(this.id));
		// 	url = '/update-vehicle-details';
		// }
		// else
		// {
			url = '/contract';
		// }

		this.mainApiService.postData(appConfig.base_url_slug + url, this.Form.value).then(response => {
			if (response.status == 200 || response.status === 201)
			{
				// this.router.navigateByUrl('/main/contracts/list')
				this.onLocationBack();
			}
			else
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}

	ngOnDestroy(): void
	{
		this.sub.unsubscribe();
	}
}
