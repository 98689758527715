
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AppComponent } from './app.component';
import { LoginComponent } from './views/auth/login/login.component';
import { TemplateMainApp } from './templates/template-main-app/template-main-app.component';

import { AuthGuard, MainAuthGuard } from './guards';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { OrdersListComponent, OrdersFormComponent, AssignOrderComponent } from './views/orders';
import {DashboardComponent} from "./views/dashboard/dashboard.component";
import { CustomersListComponent, CustomersFormComponent } from './views/customers';
import { DriversListComponent, DriversFormComponent } from './views/drivers';
import { VehiclesListComponent, VehiclesFormComponent, AssignVehicleComponent } from './views/vehicles';
import { ManageAddress } from './views/address/manage-address.component';
import { ContractsListComponent } from './views/contracts/contracts_list.component';
import { ContractsFormComponent } from './views/contracts/contracts_form.component';
import { ContractOnDemandComponent } from './views/contracts/contract-ondemand.component';
import { TrackOrdersComponent } from './views/orders/track-orders.component';
import { EditContractsComponent } from './views/contracts/edit_contract.component';
import { AllTrackOrdersComponent } from './views/orders/all_orders_tracking.component';


const publicRoutes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'reset-pin', component: ResetPasswordComponent },
];

const mainApp: Routes = [
	{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
	{ path: 'dashboard', component: DashboardComponent },

	{ path: 'customers/list/:type', component: CustomersListComponent },
	{ path: 'customers/list/:type/edit/:id/manage-address', component: ManageAddress },
	{ path: 'customers/form/:id', component: CustomersFormComponent },
	{ path: 'customers/list/:type/edit/:id', component: CustomersFormComponent },

	{ path: 'drivers/list', component: DriversListComponent },
	{ path: 'drivers/form/:id', component: DriversFormComponent },
	{ path: 'drivers/list/edit/:id', component: DriversFormComponent },
	
	{ path: 'drivers/list/edit/:id/assign', component: AssignVehicleComponent },

	{ path: 'customers/list/:type/contracts/:partner_id', component: ContractsListComponent },
	{ path: 'customers/list/:type/schedule/:partner_id', component: ContractsFormComponent },
	{ path: 'customers/list/:type/ondemand/:partner_id', component: ContractOnDemandComponent },
	{ path: 'customers/list/:type/contracts/:partner_id/edit/:id', component: EditContractsComponent },


	{ path: 'vehicles/list', component: VehiclesListComponent },
	{ path: 'vehicles/form/:id', component: VehiclesFormComponent },
	{ path: 'vehicles/list/edit/:id', component: VehiclesFormComponent },

	{ path: 'orders/list/:type', component: OrdersListComponent },
	{ path: 'orders/tracking', component: AllTrackOrdersComponent },
	{ path: 'orders/list/:type/track/:id', component: TrackOrdersComponent },
	{ path: 'orders/form/:id', component: OrdersFormComponent },
	{ path: 'orders/list/:type/edit/:id', component: OrdersFormComponent },
	// { path: 'orders/assign', component: AssignOrderComponent },

];

const appRoutes: Routes = [
	{ path: '', redirectTo: '/auth/login', pathMatch: 'full' },
	{ path: 'auth', component: AppComponent, children: publicRoutes, canActivate: [AuthGuard] },
	{ path: 'main', component: TemplateMainApp, children: mainApp, canActivate: [MainAuthGuard] },
	// { path: 'main', component: TemplateMainApp, children: mainApp },
];


@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule { }
