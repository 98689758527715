import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { PaginationService } from "../../services/pagination.service";
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';


declare var $: any;


@Component({
    selector: 'manage-address',
    templateUrl: 'manage-address.component.html',
    styleUrls: ['manage-address.component.scss'],
})
export class ManageAddress implements OnInit {


    contractForm: FormGroup;
    Items: any = [];

    isAddAddress = false;

    editItem: any = "";
    url = '';

    index: any = 1;
    totalPages: number;
    pages: any;
    totalItems: any;
    currentPage: any = 1;
    perPage: number = 20;
    count: any;
    Customers: any = [];
    user_id: string = '';
    sub: any;

    constructor(protected _route: ActivatedRoute,private mainService: MainService, protected paginationService: PaginationService, protected dialog: MatDialog) {

    }


    ngOnInit() 
    {
        this.sub = this._route.params.subscribe(params => {
			this.user_id = params['id'];
        });
        this.getAddressList();
        // this.getCustomersList();
    }

    onAddAddress() {
        // this.router.navigateByUrl('/main/add-address')
        this.editItem = "";
        this.isAddAddress = true;
    }

    onChange(e: any) {
        this.isAddAddress = e;
        this.getAddressList();
    }

    // getCustomersList(): void 
	// {
	// 	let url = '/view-users?page=' + 1 + '&per_page=' + this.perPage + '&role_type=partner&user_status=1';
	// 	this.mainService.getList(appConfig.base_url_slug + url).then(result => 
	// 	{
	// 		if (result.status === 200 && result.data) 
	// 		{
	// 			this.Customers = result.data;
	// 		}
	// 		else 
	// 		{
	// 			this.Customers = [];
	// 		}
	// 	});
	// }


    getAddressList(): void 
	{
		let url = '/view-location?id=' + this.user_id;
		this.mainService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Items = result.data;
			}
			else 
			{
				this.Items = [];
			}
		});
    }
    
    onLocationBack(): void
	{
		window.history.back();
	}


    onEdit(item: any) {
        this.editItem = item;
        this.isAddAddress = true;
    }

    onDelete(item: any) 
    {
        event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Delete Address';
		cm.message = 'Are you sure you want to delete this Address?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = appConfig.base_url_slug + '/delete-location?id='+item.id;
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
            if(result == true)
            {
				this.getAddressList();
			}
		});
    }
}


