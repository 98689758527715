import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Subscription } from "rxjs";
import { appConfig } from 'src/config';


declare var $: any;


@Component({
    selector: 'track-orders',
    templateUrl: 'track-orders.component.html',
    styleUrls: ['track-orders.component.scss'],
})
export class TrackOrdersComponent implements OnInit, OnDestroy {
    mapLocation: { lat: number; lng: number; label: string; }[] = [];
    ngOnDestroy(): void 
    {
        clearTimeout(this.searchTimer);
    }

    addItemForm: FormGroup;
    addShipmentDatesForm: FormGroup;
    order: any;
    orderItems: any = [];
    vehicleDetails: any;
    driverDetails: any;
    partnerDetails: any;
    currentPage: any = 1;
    perPage: number;
    index: any = 1;
    totalPages: number;

    stageCheck = 1;
    isMap = false;

    order_id: any;
    sub: Subscription;
    url: any = 'order-track?order_id=';
    searchTimer: any;

    constructor(private router: Router, protected _route: ActivatedRoute, private mainService: MainService, protected formbuilder: FormBuilder) {

        this.addItemForm = this.formbuilder.group({
            itemType: [null],
            tempMin: [null],
            tempMax: [null],
            quantity: [null],
        });
        this.addShipmentDatesForm = this.formbuilder.group({
            // date: [null],
            time: [null],
        })

        this.partnerDetails = JSON.parse(localStorage.getItem('MTCAdmin'));

    }

    ngOnInit() {
        this.sub = this._route.params.subscribe(params => {
            this.order_id = params['id'];
            console.log(this.order_id);

            this.url = this.url + this.order_id;
            this.getList();
        });

    }

    getList() {
        // this.url = 'all-orders?status='+this.type;
        this.mainService.getList(appConfig.base_url_slug + '/' + this.url).then(response => {
            if (response.status == 200 || response.status === 201) {
                console.log(response);
                this.order = response.order[0];
                this.orderItems = response.orderItems;
                this.vehicleDetails = response.vehicleDetails;
                this.driverDetails = response.driverDetails;

                this.mapLocation = [
                    {
                        lat: parseInt(this.order.pickup_latitude),
                        lng: parseInt(this.order.pickup_longitude),
                        label: 'Pick-up Location'
                    },
                    {
                        lat: parseInt(this.order.dropoff_latitude),
                        lng: parseInt(this.order.dropoff_longitude),
                        label: 'Drop-off Location'
                    }
                ];
            }
            else {

            }
        },
            Error => {

            })

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getList();
        }, 5000);
    }
    onBack() {
        window.history.back();
    }
    onStageClick(e) {
        this.stageCheck = e;
    }

    onMap(e) {
        this.isMap = e;
    }
}


