import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgularMaterialModule } from '../material.module';
import { FilterDatePipe } from './filterDate.pipe';
import { AlertDialog } from './alert.dialog';
import { PaginationComponent } from './pagination/pagination.component';
import { Card } from './card/card.component';
import { ApiLoaderInterceptorService } from './api-loader/api-loader.interceptor.service';
import { ApiLoaderComponent } from './api-loader/api-loader.component';
import { ApiLoaderService } from './api-loader/api-loader.service';
import { LocationViewComponent } from './location-view/location-view.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoogleMapsAPIWrapper, AgmCoreModule } from '@agm/core';
import { appConfig } from 'src/config';
import { MapLocationDialog } from './map-location/map-location.dialog';
import { LocationPicker } from './location-picker/location-picker';
import { LocationPickerDialog } from './location-picker/location_dialog';
import { LocationMultiViewComponent } from './location-multi-view/location-multi-view.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        AgularMaterialModule,
        AgmCoreModule.forRoot({
            apiKey: appConfig.google_api_key,
            libraries: ['places']
        }),
    ],
    declarations: [
        FilterDatePipe, 
        AlertDialog, 
        PaginationComponent, 
        Card, 
        ApiLoaderComponent, 
        LocationViewComponent,
        MapLocationDialog,
        LocationPicker,
        LocationPickerDialog,
        LocationMultiViewComponent
    ],
    exports: [
        FilterDatePipe, 
        AlertDialog, 
        PaginationComponent, 
        Card, 
        ApiLoaderComponent, 
        LocationViewComponent,
        MapLocationDialog,
        LocationPicker,
        LocationPickerDialog,
        LocationMultiViewComponent
    ],
    providers: [
        GoogleMapsAPIWrapper,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiLoaderInterceptorService,
            multi: true
        },
        ApiLoaderService
    ],
    entryComponents: [
        AlertDialog,
        LocationViewComponent,
        MapLocationDialog,
        LocationPickerDialog,
        LocationMultiViewComponent
    ],
})
export class LibModule { }
