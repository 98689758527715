
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MainService } from '../../services/main.service';
import { appConfig } from '../../../config';


declare var $: any;

export class SideMenuItems {
	routerLink?: string;
	image?: string;
	label?: string;
	is_parent?: boolean;
	children?: SideMenuItems[];
	opened?: boolean;
}

@Component({
	selector: 'template-main-app',
	templateUrl: 'template-main-app.component.html',
	styleUrls: ['template-main-app.component.scss'],
})
export class TemplateMainApp implements OnInit, OnDestroy
{
	public scrollbarOptions = { axis: 'yx', theme: 'minimal' };
	menus: SideMenuItems[];
	adminClick:any;
	constructor(protected mainApiService: MainService, private router: Router, protected activeRoute: ActivatedRoute)
	{
		this.menus = [];

		this.activeRoute.params.subscribe(routeParams => {
			// console.log(routeParams)
		})

		router.events.subscribe((val) => {
			// see also 
			if(val instanceof NavigationEnd)
			{
				// console.log(val)
			}
		});
	}

	ngOnInit()
	{
		this.menus = [
			{ routerLink: '/main/dashboard', image: 'dashboard', label: 'Dashboard' },

			{
				image: 'order',
				label: 'Order Management',
				is_parent: true,
				opened: false,
				children: [
					{ routerLink: '/main/orders/form/add', image: 'order', label: 'Add New Order' },
					{ routerLink: '/main/orders/list/pending', image: 'order', label: 'Pending Orders' },
					{ routerLink: '/main/orders/list/all', image: 'order', label: 'Orders List' },
					{ routerLink: '/main/orders/list/completed', image: 'order', label: 'Completed Orders' },
					// { routerLink: '/main/orders/assign', image: 'order', label: 'Assign Orders' },
					{ routerLink: '/main/orders/tracking', image: 'order', label: 'Orders Tracking' },
					{ routerLink: '/main/orders/scheduler', image: 'order', label: 'Orders Scheduler' },
				]
			},
			{
				image: 'customer',
				label: 'Customers',
				is_parent: true,
				opened: false,
				children: [
					{ routerLink: '/main/customers/form/add', image: 'customer', label: 'Add New Customer' },
					{ routerLink: '/main/customers/list/all', image: 'customer', label: 'Customers List' },
					{ routerLink: '/main/customers/list/pending', image: 'customer', label: 'Pending Customers' },
					{ routerLink: '/main/customers/list/withoutcontract', image: 'customer', label: 'Without Contract Customers' },
					{ routerLink: '/main/customers/list/registered', image: 'customer', label: 'Registered Customers' },
					{ routerLink: '/main/customers/list/rejected', image: 'customer', label: 'Rejected Customers' },
					// { routerLink: '/main/customers/manage-address', image: 'customer', label: 'Addresses' },
				]
			},
			// {
			// 	image: 'content-management',
			// 	label: 'Contracts',
			// 	is_parent: true,
			// 	opened: false,
			// 	children: [
					// { routerLink: '/main/contracts/form/add', image: 'content-management', label: 'Add Scheduled Contract' },
					// { routerLink: '/main/contracts/ondemand/add', image: 'content-management', label: 'Add OnDemand Contract' },
					// { routerLink: '/main/contracts/list', image: 'content-management', label: 'Contracts List' },
			// 	]
			// },
			{
				image: 'driver',
				label: 'Driver Management',
				is_parent: true,
				opened: false,
				children: [
					{ routerLink: '/main/drivers/form/add', image: 'driver', label: 'Add New Driver' },
					{ routerLink: '/main/drivers/list', image: 'driver', label: 'Drivers List' },
				]
			},
			{
				image: 'vehicle',
				label: 'Vehicle Management',
				is_parent: true,
				opened: false,
				children: [
					{ routerLink: '/main/vehicles/form/add', image: 'vehicle', label: 'Add New Vehicle' },
					{ routerLink: '/main/vehicles/list', image: 'vehicle', label: 'Vehicles List' },
					// { routerLink: '/main/vehicles/assign', image: 'vehicle', label: 'Assign Vehicles' },
				]
			},
			{ routerLink: '/main/system', image: 'system', label: 'System Management' },

		];

		$("a.nav-dropdown-trigger").on("click", function (e: any) {
			e.preventDefault();
			var $this = $(this);
			$this.parent(".sub-nav").toggleClass("opened");
		});

		$(".menu-trigger").on("click", function (e: any) {
			e.preventDefault();
			var $this = $(this);
			$this.toggleClass("active");
			$this.parents(".dashboard-main").find(".page-sidebar").toggleClass("opened");
			$this.parents(".dashboard-main").find(".page-content").toggleClass("opened");
		});
		this.onGetDefault();
	}

	ngOnDestroy()
	{
		$(".sidebar-nav a.nav-dropdown-trigger").off("click", function (e: any)
		{
			e.preventDefault();
		});
	}

	onLogout(): void
	{
		this.mainApiService.onLogout().then (result =>
		{
			if (result.status === 200)
			{
				localStorage.clear();
				window.location.reload();
			}
		});
	}

	onGetDefault(): void
	{
		this.mainApiService.getList('coordinator/home-defaults').then (result =>
		{
			localStorage.setItem('Defaults', JSON.stringify(result));
			localStorage.setItem('Cities', JSON.stringify(result.cities));
			localStorage.setItem('Countries', JSON.stringify(result.countries));
		});
	}

	onHomeClick(): void
	{
		this.router.navigate(['/main']);
	}

	onMenuClick(menu, event): void
	{
		localStorage.removeItem('pageSettings');
		event.preventDefault();
		event.stopPropagation();

		this.menus.forEach(element => {
			if(element.label != menu.label)
			{
				element.opened = false;
			}
		});
		menu.opened = !menu.opened;
	}

	onChildClick(menu, event): void
	{
		localStorage.removeItem('pageSettings');
		event.preventDefault();
		event.stopPropagation();
		// console.log('Child', menu);
	}
}


