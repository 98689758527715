import {
    Component, OnInit, Input, ElementRef, Output, EventEmitter, AfterViewInit, forwardRef, ViewChild,
    OnChanges, SimpleChanges
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import {LocationPickerDialog} from './location_dialog';
// import { LibService } from '../lib.service';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LocationPicker),
    multi: true
};

const noop = () => {
};

@Component({
    selector: 'location-picker',
    templateUrl: './location-picker.html',
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    styles: [`
        .controlWrapper {
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            margin-bottom: 10px;
        }
    `]
})

export class LocationPicker implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges
{
    @Input() formControlName: any;
    @Input() width: number;
    @Input() height: number;
    @Input() placeholder: any;
    @Input() formatedAddressInput: any;

    @Input() innerValue: any = '';

    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('mapImage') elementMapImage: ElementRef;

    staticMapSrc: string;

    // The internal data model

    // Placeholders for the callbacks which are later providesd
    // by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
    formatedAddress:any = "";
    private column: any;

    viewInited: boolean;

    constructor(private elmement: ElementRef, private dialog: MatDialog)
// , protected _libService: LibService)
    {
        this.column = elmement.nativeElement.getAttribute('formControlName');

        // this.placeholder = "Select location";
        this.width = 200;
        this.height = 200;

        this.viewInited = false;
    }

    ngOnInit(): void {
        if(this.formatedAddressInput){
            this.formatedAddress = this.formatedAddressInput;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log("changes", changes);
        if(this.formatedAddressInput){
            this.formatedAddress = this.formatedAddressInput;
        }
    }
    ngAfterViewInit(): void
    {
        this.viewInited = true;
        // this.buildStaticMapSrc();
    }

    /*buildStaticMapSrc(): void
    {
        if(!this.viewInited)
            return;
        if(this.value == void 0 || this.value == null)
            return;

        let latLng = this.value;
        let str = 'https://maps.googleapis.com/maps/api/staticmap?zoom=14';
        str = str + "&center=" + latLng;
        str = str + "&size=" + this.elementMapImage.nativeElement.offsetWidth + "x" + (this.height - 24);
        str = str + "&markers=color:red|size:mid|" + latLng;
        // str = str + "&key=" + this._libService.google_api_key;

        this.staticMapSrc = str;
    }*/

    onPickerClick(): void
    {
        let dialogRef = this.dialog.open(LocationPickerDialog);

        dialogRef.componentInstance.initialLocation = this.innerValue;

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result != null )
            {
                console.log("OnPickerClick result:", result);
                this.value = result.latlng;
                this.formatedAddress = result.formatted_address;
                //this.latLng = result;
                // this.buildStaticMapSrc();
                this.onSelectionChange.emit(result);
            }
        });
    }

    private getChildValue(value) {
        return value;
    }

    // get accessor
    get value(): any {
        return this.innerValue;
    };

    // set accessor including call the onchange callback
    set value(v: any)
    {
        v = this.getChildValue(v);
        if (v !== this.innerValue)
        {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    // From ControlValueAccessor interface
    writeValue(v: any)
    {
        v = this.getChildValue(v);

        if (v !== this.innerValue) {
            this.innerValue = v;
        }

        // this.buildStaticMapSrc();
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}
