
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { appConfig } from 'src/config';
import { AlertDialog } from 'src/app/lib';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PaginationService } from 'src/app/services';


declare var $: any;


@Component({
	selector: 'app-assign-order',
	templateUrl: 'assign-order.component.html'
})
export class AssignOrderComponent implements OnInit 
{
	Form: FormGroup;
	Vehicles: any = [];
	isEditing: any;
	order: any;

	constructor(private mainApiService: MainService, protected fb: FormBuilder, protected dialog: MatDialog, protected paginationService: PaginationService, protected dialogRef: MatDialogRef<AssignOrderComponent>) 
	{
		this.Form = this.fb.group({
			order_id: [null, Validators.required],
			vehicle_id: [null, Validators.required]
		});
	}

	ngOnInit() 
	{
		this.Form.get('order_id').setValue(this.order.id);
		if(this.order.vehicle_id)
		{
			this.Form.get('vehicle_id').setValue(this.order.vehicle_id);
		}
		this.getVehiclesList();
	}

	getVehiclesList(): void
	{
		let url = '/view-vehicle';
		this.mainApiService.getList(appConfig.base_url_slug + url).then(result => {
			if (result.status === 200  && result.data)
			{
				this.Vehicles = result.data;
			}
			else
			{
				this.Vehicles = [];
			}
		});
	}

	onSubmit(): void
	{
		let url = '';
		if(this.isEditing)
		{
			url = '/update-assignorder?order_id='+ this.Form.get('order_id').value +'&vehicle_id=' + this.Form.get('vehicle_id').value;
		}
		else
		{
			url = '/assignorder-vehicle/'+ this.Form.get('order_id').value +'/' + this.Form.get('vehicle_id').value;
		}

		this.mainApiService.getList(appConfig.base_url_slug + url).then(response => {
			if (response.status == 200 || response.status === 201)
			{
				this.isEditing = false;
				this.dialogRef.close(true);
			}
			else
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = response.error.error.message;
				cm.cancelButtonText = 'Ok';
				cm.type = 'error';
			}
		},
		Error => {
			let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
			let cm = dialogRef.componentInstance;
			cm.heading = 'Error';
			cm.message = "Internal Server Error.";
			cm.cancelButtonText = 'Ok';
			cm.type = 'error';
		})
	}

	onClose(): void
	{
		this.dialogRef.close(false);
	}
}


