import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Subscription } from "rxjs";
import { appConfig } from 'src/config';


declare var $: any;


@Component({
    selector: 'all_orders_tracking',
    templateUrl: 'all_orders_tracking.component.html',
    styleUrls: ['track-orders.component.scss'],
})
export class AllTrackOrdersComponent implements OnInit 
{
    Orders: any = [];
    selectedId: any;
    
    order: any;
    orderItems: any = [];
    stageCheck = 1;
    url: any = 'order-track?order_id=';
    vehicleDetails: any;
    driverDetails: any;
    perPage: string;
    constructor(private router: Router, protected _route: ActivatedRoute, private mainService: MainService, protected formbuilder: FormBuilder) 
    {


    }

    ngOnInit() 
    {
        this.getOrdersList(1);
    }

    onViewOrder(item) 
    {
        this.selectedId = item.id;
        this.mainService.getList(appConfig.base_url_slug + '/' + this.url + item.id).then(response => 
        {
            if (response.status == 200 || response.status === 201) 
            {
                this.order = response.order[0];
                this.orderItems = response.orderItems;
                this.vehicleDetails = response.vehicleDetails;
                this.driverDetails = response.driverDetails;
            }
            else 
            {

            }
        });
    }

    getOrdersList(index): void 
    {
        let url = '/all-orders' + '?page=' + index + '&status=all' + '&per_page=' + this.perPage + '&urgency=all';
        this.mainService.getList(appConfig.base_url_slug + url).then(result => 
        {
            if (result.status === 200 && result.data) 
            {
                this.Orders = result.data;
                this.onViewOrder(this.Orders[0]);
            }
            else 
            {
                this.Orders = [];
            }
        });
    }

    onStageClick(item)
    {

    }
}


