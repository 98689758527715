
import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { MatDialogRef } from '@angular/material';


@Component({
	selector: 'order_detail',
    templateUrl: 'order_detail.component.html',
    styleUrls: ['track-orders.component.scss'],
})
export class OrderDetailComponent implements OnInit 
{
	order: any;

	constructor(private mainApiService: MainService, protected dialogRef: MatDialogRef<OrderDetailComponent>) 
	{

	}

	ngOnInit() 
	{
	}

	onSubmit(): void
	{
        this.dialogRef.close(false);
		// let url = '';
		// if(this.isEditing)
		// {
		// 	url = '/update-assginvehicle?order_id='+ this.Form.get('order_id').value +'&vehicle_id=' + this.Form.get('vehicle_id').value;
		// }
		// else
		// {
		// 	url = '/assignorder-vehicle/'+ this.Form.get('order_id').value +'/' + this.Form.get('vehicle_id').value;
		// }

		// this.mainApiService.getList(appConfig.base_url_slug + url).then(response => {
		// 	if (response.status == 200 || response.status === 201)
		// 	{
		// 		this.isEditing = false;
		// 		this.dialogRef.close(true);
		// 	}
		// 	else
		// 	{
		// 		let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
		// 		let cm = dialogRef.componentInstance;
		// 		cm.heading = 'Error';
		// 		cm.message = response.error.error.message;
		// 		cm.cancelButtonText = 'Ok';
		// 		cm.type = 'error';
		// 	}
		// },
		// Error => {
		// 	let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
		// 	let cm = dialogRef.componentInstance;
		// 	cm.heading = 'Error';
		// 	cm.message = "Internal Server Error.";
		// 	cm.cancelButtonText = 'Ok';
		// 	cm.type = 'error';
		// })
	}

	onClose(): void
	{
		this.dialogRef.close(false);
	}
}


