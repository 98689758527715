import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MainService, BaseLoaderService, PaginationService } from '../../services';


@Component({
	selector: 'app-dashboard',
	templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit
{
	Defaults: any;
	constructor(protected router: Router,
		protected _route: ActivatedRoute,
		protected mainApiService: MainService,
		protected paginationService: PaginationService)
	{

	}
	
    ngOnDestroy(): void 
	{
	}
	

	ngOnInit() 
	{
		// this.Defaults = JSON.parse(localStorage.getItem('Defaults'));
		// console.log(this.Defaults);
		// if(this.Defaults == null)
		// {
			this.onGetDefault();
			this.onGetFreezers();
		// }
	}

	onGetDefault(): void
	{
		this.mainApiService.getList('coordinator/home-defaults').then (result =>
		{
			this.Defaults = result;
			localStorage.setItem('Defaults', JSON.stringify(result));
			localStorage.setItem('Cities', JSON.stringify(result.cities));
			localStorage.setItem('Countries', JSON.stringify(result.countries));
		});
	}

	onGetFreezers(): void
	{
		this.mainApiService.getList('coordinator/all-freezers').then (result =>
		{
			console.log(result)
		});
	}
}
