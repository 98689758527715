
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from '../../services/main.service';
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {MatDialog} from "@angular/material";
import {AlertDialog} from "../../lib/alert.dialog";
import { appConfig } from 'src/config';
import * as moment from 'moment';
import { Subscription } from 'rxjs';


@Component({
	selector: 'contract-ondemand',
	templateUrl: 'contract-ondemand.component.html'
})
export class ContractOnDemandComponent implements OnInit {


	Form: FormGroup;
	// Items:any = [];
	// url:any = 'add-contract';
	// addButtonText :any = 'Add';

	// currentPage:  any = 1;
	// perPage: number;
	// index:  any = 1;
	// totalPages: number;
	// editItem:any = {};
	Addresses: any = [];
	pickUp: any;
	dropOff: any;
	// Customers: any = [];
	pickup_address: any;
	dropoff_address: any;
	onlyTime: any;
	currentDate = new Date();
	PartnerID: string = 'add';
	sub: Subscription;

	constructor(protected _route: ActivatedRoute, private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog) {

		this.Form = this.formbuilder.group({
			pickup_latitude: [null, [Validators.required]],
			pickup_longitude: [null, [Validators.required]],
			pickup_address: [null, [Validators.required]],

			dropoff_latitude: [null, [Validators.required]],
			dropoff_longitude: [null, [Validators.required]],
			dropoff_address: [null, [Validators.required]],


			price: [null, [Validators.required]],
			discount: [null, [Validators.required]],
			partner_id: [null, [Validators.required]],

			pickup_units: [null],
			pickup_time: [null],
			
			contract_type: ['ondemand'],
		});

		
	}

	ngOnInit() 
	{
		this.sub = this._route.params.subscribe(params => {
			this.PartnerID = params['partner_id'];
			this.Form.get('partner_id').setValue(this.PartnerID);

			this.getAddressList();
		});

		this.Form.get('partner_id').valueChanges.subscribe(result => {
			this.getAddressList();
		})
		// this.getList();
		// this.getCustomersList();
	}

	doSubmit()
	{
		this.mainService.postData(appConfig.base_url_slug + '/add-ondemand-contract', this.Form.value).then(response => {
			if (response.status == 200 || response.status === 201) 
			{
				// this.Form.reset();
				// this.getList();
				// this.editItem = "";
				// this.url= 'add-contract';
				// this.addButtonText = 'Add';
				this.onLocationBack();
			}
			else 
			{
				let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = 'Internal Server Error';
				cm.cancelButtonText = 'Close';
				cm.type = 'error';
			}
		});
	}
	
	onLocationBack(): void
	{
		window.history.back();
	}
	
	// getCustomersList(): void 
	// {
	// 	let url = '/view-users?page=' + 1 + '&per_page=' + 50 + '&role_type=partner&user_status=1';
	// 	this.mainService.getList(appConfig.base_url_slug + url).then(result => 
	// 	{
	// 		if (result.status === 200 && result.data) 
	// 		{
	// 			this.Customers = result.data;
	// 			if (this.PartnerID != 'add')
	// 			{
	// 				this.Form.get('partner_id').setValue(parseInt(this.PartnerID));
	// 			}
	// 		}
	// 		else 
	// 		{
	// 			this.Customers = [];
	// 		}
	// 	});
	// }

	onSelectTime(): void 
	{
		let oT = moment(this.onlyTime).format('HH:mm:ss');
		this.Form.get('pickup_time').setValue(oT);
	}

	// getList()
	// {
	// 	var url = '/all-contracts';
	// 	this.mainService.getList(appConfig.base_url_slug + url).then(response => {
	// 		if (response.status == 200 || response.status === 201) {
	// 			console.log(response.data);
	// 			response.data.forEach(element => {
	// 				if(element.contract_type == 'ondemand')
	// 				{
	// 					this.Items.push(element);
	// 				}
	// 			});
	// 			// this.Items = response.data;
	// 		}
	// 	})
	// }

	// onViewReview()
	// {

	// }

	getAddressList(): void 
	{
		let url = '/view-location?id=' + this.PartnerID;
		this.mainService.getList(appConfig.base_url_slug + url).then(result => 
		{
			if (result.status === 200 && result.data) 
			{
				this.Addresses = result.data;
			}
			else 
			{
				this.Addresses = [];
			}
		});
	}
	
	onPickupChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.pickUp = result[0];

		this.Form.get('pickup_latitude').setValue(this.pickUp.latitude);
		this.Form.get('pickup_longitude').setValue(this.pickUp.longitude);
		this.Form.get('pickup_address').setValue(this.pickUp.address);
	}

	onDropoffChange(addressId: any): void
	{
		const result = this.Addresses.filter(add => addressId === add.id);
		this.dropOff = result[0];

		this.Form.get('dropoff_latitude').setValue(this.dropOff.latitude);
		this.Form.get('dropoff_longitude').setValue(this.dropOff.longitude);
		this.Form.get('dropoff_address').setValue(this.dropOff.address);
	}

	// onEdit(item :any)
	// {
	// 	this.Form.patchValue(item);
	// 	this.editItem = item;
	// 	this.addButtonText = 'Update';
	// 	this.url = '/update-contract/'+item.id;
	// }

	// onEdit(item): void
	// {
	// 	this.router.navigateByUrl('/main/contracts/list/edit/' + item.id);
	// }

	// onClear()
	// {
	// 	this.addButtonText = 'Add';
	// 	this.url = 'add-contract';
	// }

	// onDelete(item :any)
	// {
	// 	event.stopPropagation();
	// 	let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
	// 	let cm = dialogRef.componentInstance;
	// 	cm.heading = 'Delete Contract';
	// 	cm.message = 'Are you sure you want to delete this contract?';
	// 	cm.submitButtonText = 'Yes';
	// 	cm.cancelButtonText = 'No';
	// 	cm.type = 'ask';
	// 	cm.methodType = 'get';
	// 	cm.methodName = 'delete-contract?id='+item.id;
	// 	cm.showLoading = true;

	// 	dialogRef.afterClosed().subscribe(result => {
	// 		if(result == true){
	// 			this.getList();
	// 		}
	// 	});
	// }
}


